import Image from 'next/image';
import Link from 'next/link';

import { Button, type ButtonProps } from '@/components/atoms';
import { Z_INDEX } from '@/constants';
import { cn } from '@/lib/utils';

type TCtaButton = ButtonProps & {
  url: string;
};
type HeroBannerProps = {
  heroTitle: string;
  heroSubtitle?: string;
  hasSecondaryButton?: boolean;
  imageUrl?: string;
  imageWrapperClassName?: string;
  videoUrl?: string;
  minHeight?: string;
  primaryButton?: TCtaButton;
  secondaryButton?: TCtaButton;
  backgroundColor?: string;
};

function HeroBanner({
  heroTitle,
  heroSubtitle,
  imageUrl,
  imageWrapperClassName,
  videoUrl,
  minHeight = '',
  primaryButton,
  secondaryButton,
  backgroundColor = '',
}: Readonly<HeroBannerProps>) {
  return (
    <div className="w-full bg-bg-default" style={{ backgroundColor }}>
      <div className="container relative">
        <div
          className={cn(
            `flex w-full flex-col justify-between gap-10 overflow-hidden lg:flex-row lg:gap-16 ${minHeight} lg:items-center ${Z_INDEX.standard1} py-10 sm:py-12`,
            videoUrl && '!pt-0'
          )}
          style={{ minHeight: videoUrl ? 260 : 342 }}
        >
          <div
            className={`order-2 flex h-full max-w-full flex-col justify-center gap-6 sm:gap-6 lg:order-1 lg:min-w-[440px] lg:max-w-[440px] ${Z_INDEX.standard2}`}
          >
            <div>
              <h2 className="display-small text-balance">{heroTitle}</h2>
              {heroSubtitle && <h3 className="subtitle1-regular mt-4 lg:mt-6">{heroSubtitle}</h3>}
            </div>

            {(primaryButton || secondaryButton) && (
              <div className="flex flex-col items-center gap-4 sm:flex-row">
                {primaryButton && (
                  <Link
                    href={primaryButton.url}
                    className={cn('w-full', !secondaryButton && 'sm:w-fit')}
                    prefetch={false}
                  >
                    <Button color="secondary" className="w-full" size="lg">
                      {primaryButton.children}
                    </Button>
                  </Link>
                )}
                {secondaryButton && (
                  <Link
                    href={secondaryButton.url}
                    className={cn('w-full', !primaryButton && 'sm:w-fit')}
                    prefetch={false}
                  >
                    <Button color="secondary" className="w-full" variant="outlined" size="lg">
                      {secondaryButton.children}
                    </Button>
                  </Link>
                )}
              </div>
            )}
          </div>

          {imageUrl && (
            <div className={cn('order-1 h-full flex-grow lg:order-2', imageWrapperClassName)}>
              <Image
                src={imageUrl}
                alt="Hero Banner"
                width={1320}
                height={492}
                className="h-auto min-h-[8rem] w-full flex-grow rounded-[1.5rem] object-cover object-top lg:h-[246px]"
              />
            </div>
          )}
          {videoUrl && (
            <video
              autoPlay
              loop
              muted
              playsInline
              className={`static inset-0 order-1 h-full w-full object-right md:top-[10%] md:h-[80%] lg:absolute lg:order-2 xl:top-0 xl:h-full ${Z_INDEX.standard1}`}
            >
              <source src={videoUrl} type="video/mp4" />
            </video>
          )}
        </div>
      </div>
    </div>
  );
}

export { HeroBanner };
