import Image from 'next/image';
import Link from 'next/link';

import { Button, type ButtonProps } from '@/components/atoms';
import { cn } from '@/lib/utils';

type TSpotlightContentProps = {
  backgroundMobile: string;
  backgroundDesktop: string;
  className?: string;
  customLogo?: React.ReactNode;
  description: string;
  logo?: string;
  primaryButton: ButtonProps & { href: string };
  titleColor?: string;
  descriptionColor?: string;
  title: string;
};
function SpotlightContent({
  backgroundMobile,
  backgroundDesktop,
  className,
  customLogo,
  description,
  logo,
  primaryButton,
  titleColor = 'text-fg-default',
  descriptionColor = 'text-fg-default',
  title,
}: Readonly<TSpotlightContentProps>) {
  function renderLogo() {
    if (customLogo) return customLogo;
    if (!logo) return null;

    return (
      <div className="flex h-14 w-fit items-center justify-center rounded-lg bg-bg-body px-4 shadow lg:h-[6.25rem] lg:w-[17.625rem]">
        <Image
          src={logo}
          alt={`${title} spot light`}
          width={282}
          height={100}
          className="max-h-[130%] w-auto"
        />
      </div>
    );
  }
  return (
    <div
      className={cn(
        'relative flex h-[460px] flex-col justify-between overflow-hidden rounded-[1.5rem] bg-left bg-no-repeat lg:h-[480px] lg:flex-row lg:items-center lg:bg-center lg:bg-cover lg:bg-no-repeat',
        className
      )}
    >
      <div className="absolute inset-0 z-20 h-full w-full p-4 lg:p-20">
        <div className="flex h-full max-w-full flex-col justify-between gap-6 lg:max-w-[420px]">
          <div className="flex flex-col gap-4 sm:gap-8">
            {renderLogo()}
            <div>
              <h3 className={cn('subtitle2-bold mb-2 text-fg-default', titleColor)}>{title}</h3>
              <p
                className={cn(
                  'subtitle2-regular w-full text-fg-default sm:w-[28rem] lg:w-full',
                  descriptionColor
                )}
              >
                {description}
              </p>
            </div>
          </div>
          <Link href={primaryButton.href} target="_blank" prefetch={false}>
            <Button className="w-full lg:w-fit" size="lg" {...primaryButton}>
              {primaryButton.children}
            </Button>
          </Link>
        </div>
      </div>

      <Image
        src={backgroundMobile}
        alt={`${title} spotlight`}
        width={1240}
        height={960}
        className="absolute inset-0 z-10 h-full w-full object-contain object-left lg:hidden"
        loading="lazy"
      />

      <Image
        src={backgroundDesktop}
        alt={`${title} spotlight`}
        width={1240}
        height={960}
        className="absolute inset-0 z-10 hidden h-full w-auto object-cover object-right lg:block"
        loading="lazy"
      />
    </div>
  );
}

export { SpotlightContent };
