import { useTranslations } from 'next-intl';
import { WhyWait } from '../WhyWait';

function LargeEnterpriseWhyWait() {
  const t = useTranslations();
  const descriptions = t.raw('largeEnterpriseWhyWaitDescription');

  const DescriptionList = (
    <ul className="ml-4 flex list-disc flex-col gap-2 sm:gap-4">
      {descriptions.map((description: string) => {
        return (
          <li className="subtitle1-regular" key={description}>
            {description}
          </li>
        );
      })}
    </ul>
  );

  return <WhyWait description={DescriptionList} hasExpertButton />;
}

export { LargeEnterpriseWhyWait };
