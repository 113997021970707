import { useTranslations } from 'next-intl';

import {} from '@/components/atoms';
import { Icon } from '@/components/atoms/Icon';
import { SegmentedButton } from '@/components/atoms/SegmentedButton';

import { LINKS } from '@/utils/getLink';
import { SpotlightContent } from './SpotlightContent';

function Spotlight() {
  const t = useTranslations();

  return (
    <div className="w-full bg-bg-body">
      <div className="container py-10">
        <h2 className="h1 mb-4 text-center sm:mb-10">{t('spotlightTitle')}</h2>
        <SegmentedButton
          centered
          color="white"
          size="lg"
          data={[
            {
              title: t('microsoftTeamsApp'),
              content: (
                <SpotlightContent
                  backgroundDesktop="/images/pages/home/spotlight/spotlight1-desktop.webp"
                  backgroundMobile="/images/pages/home/spotlight/spotlight1-mobile.webp"
                  className="bg-blue-700"
                  description={t('microsoftTeamsAppDesc')}
                  customLogo={
                    <Icon
                      name="f-teams"
                      size="xxl"
                      className="h-14 w-14 rounded-md bg-bg-body lg:h-20 lg:w-20"
                    />
                  }
                  primaryButton={{
                    href: LINKS.spotlight1,
                    children: t('getItNow'),
                    variant: 'outlined',
                    color: 'white',
                  }}
                  titleColor="text-fg-inverse-default"
                  descriptionColor="text-fg-weaker"
                  title={t('microsoftTeamsApp')}
                />
              ),
            },
            {
              title: t('thingsRemembered'),
              content: (
                <SpotlightContent
                  backgroundDesktop="/images/pages/home/spotlight/spotlight2-desktop.webp"
                  backgroundMobile="/images/pages/home/spotlight/spotlight2-mobile.webp"
                  className="bg-[#FDFBFD]"
                  description={t('spotlight2Description')}
                  logo="/images/common/logo-brand/thingsremembered.webp"
                  primaryButton={{ href: LINKS.spotlight2, children: t('shopNow') }}
                  title={t('spotlight2Title')}
                />
              ),
            },
            {
              title: t('brandedSwag'),
              content: (
                <SpotlightContent
                  backgroundDesktop="/images/pages/home/spotlight/spotlight3-desktop.webp"
                  backgroundMobile="/images/pages/home/spotlight/spotlight3-mobile.webp"
                  className="bg-blue-25"
                  description={t('spotlight3Description')}
                  logo="/images/common/logo-brand/pmall.webp"
                  primaryButton={{ href: LINKS.spotlight3, children: t('shopNow') }}
                  title={t('spotlight3Title')}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}

export { Spotlight };
