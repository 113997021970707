import { useTranslations } from 'next-intl';

import type { TLocale } from '@/constants';
import { dayjs } from '@/lib';
import { LanguageSwitcherForm } from '../molecules';

type TLanguageSwitcherProps = {
  locale: TLocale;
};
export function LanguageSwitcher({ locale }: Readonly<TLanguageSwitcherProps>) {
  const t = useTranslations();

  return (
    <div className="flex flex-col items-center gap-4">
      <p className="display">
        {t('hello')}, time is {dayjs().format('HH:mm:ss')}
      </p>

      <LanguageSwitcherForm locale={locale} />
    </div>
  );
}
