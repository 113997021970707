import { cn } from '@/lib/utils';

type TShapeProps = {
  borderWidth?: number;
  className?: string;
  color?: string;
  size?: number;
};
export function Circle({
  borderWidth = 6,
  className,
  color = 'bg-navy-500',
  size = 18,
}: Readonly<TShapeProps>) {
  return (
    <div
      className={cn('rounded-full border', color, className)}
      style={{
        width: size,
        height: size,
        borderWidth,
      }}
    />
  );
}

export function Square({
  borderWidth = 6,
  className,
  color = 'bg-navy-500',
  size = 18,
}: Readonly<TShapeProps>) {
  return (
    <div
      className={cn('rounded border', color, className)}
      style={{
        width: size,
        height: size,
        borderWidth,
      }}
    />
  );
}

export function X({
  borderWidth = 6,
  className,
  color = 'bg-navy-500',
  size = 18,
}: Readonly<TShapeProps>) {
  return (
    <div className={cn(className, color)}>
      <div
        className={cn('relative border-inherit')}
        style={{
          width: size,
          height: size,
        }}
      >
        <div
          className={cn('absolute transform border-inherit')}
          style={{
            borderWidth,
            height: '100%',
            transform: 'rotate(45deg)',
            borderRadius: 2,
            left: `calc(50% - ${borderWidth}px)`,
          }}
        />
        <div
          className={cn('absolute transform border-inherit')}
          style={{
            borderWidth,
            height: '100%',
            transform: 'rotate(-45deg)',
            borderRadius: 2,
            left: `calc(50% - ${borderWidth}px)`,
          }}
        />
      </div>
    </div>
  );
}

export function Triangle({ className, color, borderWidth = 6, size = 18 }: Readonly<TShapeProps>) {
  return (
    <div className={cn(className)}>
      <div
        className={cn('relative bg-inherit', color)}
        style={
          {
            '--r': `${size / 2}px`,
            width: `${size}px`,
            aspectRatio: `1 / ${Math.cos(Math.PI / 6)}`,
            mask: `
            conic-gradient(from -30deg at 50% calc(200% - 3*var(--r)/2),#000 60deg,#0000 0)
            0 100%/100% calc(100% - 3*var(--r)/2) no-repeat,
            radial-gradient(var(--r) at 50% calc(2*var(--r)),#000 98%,#0000 101%),
            radial-gradient(var(--r) at left  var(--_g)),
            radial-gradient(var(--r) at right var(--_g))
          `,
            '--_g': 'calc(tan(60deg)*var(--r)) bottom var(--r),red 98%,#0000 101%',
            clipPath: 'polygon(50% 0, 100% 100%, 0 100%)',
          } as React.CSSProperties
        }
      >
        <div
          className={cn('absolute z-10 bg-bg-default')}
          style={
            {
              inset: borderWidth * 2,
              top: borderWidth * 2.3,
              '--r': `${size / 4}`,
              width: `${size - borderWidth * 4}px`,
              aspectRatio: `1 / ${Math.cos(Math.PI / 6)}`,
              mask: `
            conic-gradient(from -30deg at 50% calc(200% - 3*var(--r)/2),#000 60deg,#0000 0)
            0 100%/100% calc(100% - 3*var(--r)/2) no-repeat,
            radial-gradient(var(--r) at 50% calc(2*var(--r)),#000 98%,#0000 101%),
            radial-gradient(var(--r) at left  var(--_g)),
            radial-gradient(var(--r) at right var(--_g))
          `,
              '--_g': 'calc(tan(60deg)*var(--r)) bottom var(--r),red 98%,#0000 101%',
              clipPath: 'polygon(50% 0, 100% 100%, 0 100%)',
            } as React.CSSProperties
          }
        />
      </div>
    </div>
  );
}

export const Shape = {
  Circle,
  Square,
  X,
  Triangle,
};
