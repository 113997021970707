'use client';

import { Button } from '@/components/atoms';
import { Shape } from '@/components/atoms/Shape';
import { RoleCircleItem } from '@/components/organisms';
import { cn } from '@/lib/utils';
import { LINKS, type TLink } from '@/utils/getLink';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';

export const renderEmailLink = (chunks: React.ReactNode) => (
  <a href="mailto:'corporate@1800flowers.com'" className="text-blue-500 underline">
    {chunks}
  </a>
);

type THelpfulLinkOption = {
  label: string;
  url: string;
  urlKey: string;
};
export default function NotFound() {
  const t = useTranslations();

  const helpfulLinksData = t.raw('helpfulLinksData') as THelpfulLinkOption[];

  const errorPageFooterText = t.rich('errorPageFooterText', {
    a: renderEmailLink,
  });

  return (
    <div
      className={cn(
        'container relative flex flex-col items-center justify-center gap-6 py-20 text-center',
        'max-w-[760px]'
      )}
    >
      <div>
        <RoleCircleItem
          avatarUrl="/images/pages/error/error-avatar-1.webp"
          avatarBg="bg-blue-25"
          borderBg="border-blue-300"
          bgColor="bg-blue-50"
          className="-z-10 absolute top-12 left-[10%] w-[4.25rem] sm:top-10 sm:w-[6.25rem]"
        />
        <RoleCircleItem
          avatarUrl="/images/pages/error/error-avatar-2.webp"
          avatarBg="bg-blue-25"
          borderBg="border-blue-300"
          bgColor="bg-blue-50"
          className="-z-10 absolute top-6 right-[10%] w-[4.25rem] sm:top-20 sm:w-[6.25rem]"
        />

        <Shape.Circle
          color="border-light-blue-400"
          className="absolute top-4 left-[calc(10%+3.125rem-9px)]"
        />
        <Shape.X
          color="border-light-blue-700"
          borderWidth={2}
          size={12}
          className="absolute top-12 left-[30%]"
        />
        <Shape.Circle color="border-pink-500" className="absolute top-4 right-[40%]" />
        <Shape.Circle
          color="border-pink-500"
          className="absolute top-80 left-[10%]"
          size={8}
          borderWidth={4}
        />
        <Shape.Circle color="border-light-blue-900" className="absolute top-60 left-[14%]" />
        <Shape.Circle color="border-light-blue-700" className="absolute top-36 right-[24%]" />
        <Shape.X color="border-pink-500" className="absolute top-60 right-[15%]" borderWidth={3} />
        <Shape.Circle
          color="border-light-blue-400"
          className="absolute top-80 right-[30%]"
          size={8}
          borderWidth={4}
        />
      </div>
      <Image
        src="/images/pages/error/error.webp"
        width={730}
        height={328}
        alt="404"
        className="h-[6.25rem] w-auto sm:h-40"
      />
      <h1 className="display-large">{t('oops')}</h1>

      <div className="flex w-full flex-col items-center gap-4">
        <h2 className="h2">{t('errorPageMessage')}</h2>
        <p className="subtitle2-regular">{t('helpfulLinks')}</p>
        <ul className="flex flex-wrap items-center justify-center gap-6 gap-y-0">
          {helpfulLinksData.map((link) => {
            return (
              <li key={link.label}>
                <Link href={link.urlKey ? LINKS[link.urlKey as TLink] : link.url} prefetch={false}>
                  <Button variant="link">{link.label}</Button>
                </Link>
              </li>
            );
          })}
        </ul>
        <p>{errorPageFooterText}</p>
      </div>
    </div>
  );
}
