import { useTranslations } from 'next-intl';
import Image from 'next/image';

export function Guaranteed() {
  const t = useTranslations();

  return (
    <section className="container">
      <blockquote className="lg:hidden">
        <p className="subtitle1-semibold mb-4 text-center">{t('guaranteedDescription')}</p>
      </blockquote>
      <div className="flex flex-row items-center justify-center gap-2 sm:gap-6 lg:flex-row">
        <Image
          src="/images/common/guaranteed/badge.webp"
          alt={t('guaranteedDescription')}
          width={416}
          height={416}
          className="h-20 w-20 sm:h-[6.5rem] sm:w-[6.5rem]"
        />
        <blockquote className="hidden lg:block">
          <p className="subtitle1-semibold">{t('guaranteedDescription')}</p>
        </blockquote>
        <Image
          src="/images/common/guaranteed/jim.webp"
          alt="Jim McCann"
          width={672}
          height={560}
          className="h-[7.2rem] w-[8.25rem] sm:h-[8.75rem] sm:w-[10.5rem]"
        />
        <div className="flex flex-col">
          <p className="body1-regular">Jim McCann,</p>
          <p className="body1-regular min-w-[120px]">{t('ceoFounder')}</p>
        </div>
      </div>
    </section>
  );
}
