import { useTranslations } from 'next-intl';
import { WhyWait } from '../WhyWait';

function BusinessWhyWait() {
  const t = useTranslations();

  return <WhyWait description={<p className="subtitle1-regular">{t('whyWaitDescription')}</p>} />;
}

export { BusinessWhyWait };
